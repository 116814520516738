import Header from "../../components/Header/header.jsx"
import Slider from "../../components/Carousel/carousel";
import Presta from "../Presta/presta.tsx";
import Aboutme from "../Aboutme/aboutme.tsx";
import Instacontact from "../Instacontact/instacontact.tsx";
import Footer from "../../components/Footer/footer.tsx";



function Home() {
  return (
    <div>
      <Header/>
      <Slider />
      <Presta />
      <Aboutme />
      <Instacontact />
      <Footer />
    </div>
  );
}

export default Home;
