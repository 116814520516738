import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./main.scss";
import Home from "./pages/Home/home";
import Tarifs from "./pages/Tarifs/tarifs";
import Massages from "./pages/Massages/massages";
import Mentions from "./pages/Mentions/mentions";
import Notfound from "./pages/D404/D404";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Tarifs" element={<Tarifs />} />
        <Route path="/Massages" element={<Massages />} />
        <Route path="/Mentions" element={<Mentions />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
