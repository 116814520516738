import { Link } from "react-router-dom";


function Notfound() {
  return (
    <div className="texterreur">
      <img
        src="https://i.goopics.net/2fwjn8.png"
        className="Erreurimg"
        alt="404"
      />
      <div className="bandeauerreur">
        <h4>Oups! La page que vous demandez n'existe pas.</h4>
        <Link to={"/"} activeclassname="retour">
          Retourner sur la page d'accueil
        </Link>
      </div>
    </div>
  );
}

export default Notfound;
